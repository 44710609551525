.History {
    background-color: #eee;
    padding: 10px;
}
.HistoryHospitalizace {
    background-color: #eee;
    padding: 10px;
}

.block-in-btn {
    display: inline-block;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
    border-bottom: 2px solid #3484d2;
}

.data {
    background-color: #ffffff;
    color: #000;
    border: 1px solid #aaa;
    border-radius: 3px;
}

.data-header {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    background-color: #3484d2;
    color: #fff;
    font-size: 12px;
}
.data-items {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
}
.data-items:hover {
    background-color: #f8f8f8;
}
.data-items30 {
    display: none;
}

.data-col {
    text-align: right;
    padding: 5px;
    flex-basis: 0;
    flex-grow: 1;
    border-bottom: 1px solid #eee;
}

.data-col-flat {
    text-align: right;
    padding: 0px;
    flex-basis: 0;
    flex-grow: 1;
    border-bottom: 1px solid #eee;
}


.data-colv {
    text-align: right;
    padding: 5px;
    flex-basis: 0;
    flex-grow: 1;
    border-bottom: 1px solid #eee;
}



.col-novalid {
    background-color: #f0f0f0;
}

.data-colb {
    text-align: right;
    padding: 5px;
    padding-right: 10px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    flex-basis: 0;
    flex-grow: 1;
}

.data-colbv {
    text-align: right;
    padding: 5px;
    padding-right: 10px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    flex-basis: 0;
    flex-grow: 1;
}

.data-col-date {
    text-align: right;
    padding: 5px;
    padding-right: 10px;
    flex-basis: 0;
    flex-grow: 2;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.data-col-obec {
    text-align: left;
    padding: 5px;
    padding-right: 10px;
    flex-basis: 0;
    flex-grow: 4;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}