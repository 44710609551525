.itemd-balik-1 {
    background-color: rgba(190, 58, 70, 0.2);
    padding: 5px;
    color: #ff0000;
}
.itemd-balik-3 {
    background-color: rgba(238, 192, 53, 0.2);
    padding: 5px;
    color: #008800;
}
.itemd-balik-4 {
    background-color: rgba(238, 192, 53, 0.2);
    padding: 5px;
    color: #008800;
}
.itemd-balik-5 {
    background-color: rgba(90, 176, 165, 0.2);
    padding: 5px;
    color: #008800;
}
.itemd-balik-6 {
    background-color: rgba(90, 176, 165, 0.2);
    padding: 5px;
    color: #008800;
}