html {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}

body {
  background: #3484d2;
  color: #ffffff;
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 24px;
  font-weight: normal;
  margin:0;
  padding: 0;
}