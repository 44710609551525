.App {
  background: #3484d2;
  color: #ffffff;
  margin: 0px;
  padding: 0px;
}

.App-header {
  padding: 10px 30px;
  background-color: #1b436b;
}

.App-header H1 {
  font-size: 32px;
}

.block {
  background-color: #ffffff;
  color: #000;
  border: 1px solid #333;
  border-radius: 3px;
  margin-bottom: 10px;
}

.block-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0;
  padding: 10px;
}

.block-header h2 {
  font-size: 32px;
  margin: 0;
  padding: 0;
}
.block-header-info {
  text-align: right;
}
.header-item-container {
  display: flex;
  align-items: flex-end;
  text-align: center;
}
.header-item {
  margin-left: 20px;
  width: 120px;
}
.header-item-label {
  color: #888;
  font-size: 12px;
}
.header-item-value {
  font-size: 24px;
  margin: 0px;
  text-align: center;
}


br.line {
  display: block;
  width: 100%;
  border-bottom: 1px solid #eee;
}

.info-in {
  background-color: #ffffff;
  color: #000;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 20px;
}

.info {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}
.info-block {
  padding: 20px;
  width: 20%;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
}

.info3-block {
  padding: 20px;
  width: 60%;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
}


.info-block-last {
  padding: 20px;
  width: 20%;
  border-top: 1px solid #eee;
}

.item {
  margin-bottom: 20px;
}

.item-inline {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.item-inline-border {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.item-label {
  color: #888;
  font-size: 12px;
  flex-basis: 0;
  flex-grow: 1;
}

.item-value {
  text-align: right;
  font-size: 24px;
  margin: 5px 0;
  flex-basis: 0;
  flex-grow: 1;
}
.item-value-small {
  text-align: right;
  font-size: 16px;
  margin: 5px 0;
  flex-basis: 0;
  flex-grow: 1;
}
.item-big {
  font-size: 36px;
  margin: 10px 0;
  font-weight: bolder;
}
.item-value-label {
  text-align: right;
  color: #888;
  font-size: 12px;
  margin: 5px 0;
  flex-basis: 0;
  flex-grow: 1;
}
.item-big small {
  font-size: 16px;
  font-weight: normal;
}

.item-big-center {
  font-size: 32px;
  margin: 10px 0;
  text-align: center;
}

.item-pes {
  text-align: center;
  padding: 10px;
}
.item-pes-small {
  text-align: center;
  padding: 0px 10px;
}

.item-red {
  color: #ff0000;
}

.item-green {
  color: #008800;
}

.item-balik {
  text-align: center;
  padding: 10px;
}
.item-balik-small {
  text-align: center;
  padding: 0px 10px;
}
.item-balik-1 {
  background-color: #BE3A46;
  color: #ffffff;
}
.item-balik-3 {
  background-color: #EEC035;
  color: #000;
}
.item-balik-4 {
  background-color: #EEC035;
  color: #000;
}
.item-balik-5 {
  background-color: #5AB0A5;
  color: #ffffff;
}
.item-balik-6 {
  background-color: #5AB0A5;
  color: #ffffff;
}

.item-subvalue {
  text-align: right;
}

.action {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  border-top: 5px solid #eee;
}
.action-item {
  border: 1px solid #eee;
  border-bottom: 5px solid #eee;
  padding: 10px;
  flex-basis: 0;
  flex-grow: 1;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}
.action-item:hover {
  border-bottom: 5px solid #3484d2;
}
.action-item-active {
  border-bottom: 5px solid #3484d2;
}